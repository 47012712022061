import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './styles/About.scss';
import Colash from '../components/Colash';
import Footer from '../components/Footer';
import { AiFillCheckCircle } from "react-icons/ai";

const About = ( {about} ) => {
  return (
    <>
      <section id='aboutInicio' className="About">
        <div className="About__hero-container">
          <img src={about.imgHero} alt="nosotros" className="About__hero"/>
          <div>
            <h1> TALLER </h1>
            <p> <Link to='/' >Inicio</Link>  - Taller </p>
          </div>
        </div>
        <div className="About__content">
          <div className="About__contendt-grid">
            <div>
              <img src={about.decorate} alt=""/>
            </div>
            <div>
              <h1> {about.title} </h1>
              <p> {about.details} </p>
              {
                about.lista.map( lista => (
                  <ul key={lista.id} >
                    <li>
                      <AiFillCheckCircle />
                      <span> {lista.item} </span>
                    </li>
                  </ul>
                ) )
              }
              <div className="About__content-hours">
                <h3> Nuestros Horarios </h3>
                <div className='About__content-hours-grid' >
                  <div> DÍAS </div>
                  <div> HORAS </div>
                  <div> Lunes - Viernes </div>
                  <div> 10am - 7pm </div>
                  <div> Sabados </div>
                  <div> 10am - 4pm </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Colash data={about.colash} color='white' />
      <Footer />
    </>
  )
} 

const mapStateToProps = state => {
  return {
    about: state.about,
  }
}

export default connect(mapStateToProps)(About);