import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BsFillHouseDoorFill } from 'react-icons/bs';
import { FiPhoneIncoming } from 'react-icons/fi';
import { BsEnvelopeFill } from 'react-icons/bs';

import './styles/Contact.scss';
import Footer from '../components/Footer';

const Contact = ( {contact} ) => {
  return (
    <>
      <section id='contactInicio' className="Contact">
        <div className="Contact__hero-container">
          <img src={contact.imgHero} alt="nosotros" className="Contact__hero"/>
          <div>
            <h1> Contacto </h1>
            <p> <Link to='/' > Inicio </Link> - Contacto </p>
          </div>
        </div>
        <div className="Contact__form">
          <h1> {contact.title} </h1>
          <p> {contact.details} </p>
          <form action="https://formspree.io/f/myybljjq" method="POST" className="Contact__form-container">
            <div className="Contact__form-name">
              <span> {contact.name} </span>
              <input name="nombre" type="text" placeholder={contact.yourName} />
            </div>
            <div className="Contact__form-email">
              <span> {contact.email} </span>
              <input name="email" type="text" placeholder={contact.yourEmail} />
            </div>
            <div className="Contact__form-message">
              <span> {contact.message} </span>
              <textarea name="mensaje">
                {contact.yourMessage}
              </textarea>
            </div>
            <input className="Contact__button" type="submit" value={contact.button}></input>
          </form>
        </div>
        <div className="Contact__myContact-wrapper">
          <div className="Contact__myContact-content">
            <h1> Nuestro contacto </h1>
            <p> Estamos para servirte en los siguientes medios </p>
            <div className="Contact__myContact-adress">
              <BsFillHouseDoorFill />
              <div>
                <h6>Dirección</h6>
                <p> Oficinas Administrativas y Hojalateria calle 2 #13C
                    Col. Alce Blanco Naucalpan de Jueárez, Estado de México C.P 53370  </p>
              </div>
            </div>
            <div className="Contact__myContact-phone">
              <FiPhoneIncoming />
              <div>
                <h6>Telefono</h6>
                <p> T: 7160 1029 / T: 7159 9549  </p>
              </div>
            </div>
            <div className="Contact__myContact-email">
              <BsEnvelopeFill />
              <div>
                <h6>Email</h6>
                <p> atencionaclientes@hperformance.mx </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
} 

const mapStateToProps = state => {
  return {
    contact: state.contact,
  }
}

export default connect(mapStateToProps)(Contact);