import React from 'react';
import { ImFacebook } from "react-icons/im";
import {IoLogoInstagram } from "react-icons/io";
import './styles/Footer.scss';

const Footer = () => {
  return (
    <>
      <footer className='Footer' >
        <div>
          <p>
            Oficinas Administrativas y Hojalateria calle 2 #13C<br/>
            Col. Alce Blanco Naucalpan de Jueárez, Estado de México C.P 53370
          </p>
        </div>
        <div>
          <a href="https://www.facebook.com/HPerformanceWS/">
            <ImFacebook/>
          </a>
          <a href="https://instagram.com/hperformancesw?igshid=1c3m3ah84a09d">
            <IoLogoInstagram/>
          </a>
        </div>
        <div>
          <p>
            T: 7160 1029 / T: 7159 9549 <br/>
            M atencionaclientes@hperformance.mx
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer;