import React from 'react'
import Navbar from './Navbar';
import WhatsApp from '../components/WhatsApp';


const Layout = (props) => {
  return (
    <>
      <WhatsApp />
      <Navbar />
        {props.children}
    </>
  )
}

export default Layout;