import React, {useState, useRef} from 'react';
import leftArrow from '../assets/img/left-arrow.png';
import rightArrow from '../assets/img/right-arrow.png';

import './styles/Colash.scss';

const Colash = ( {data,color} ) => {
  
  const [displaygalery, setDisplaygalery ] = useState('none')
  const [imgactive, setImgactive] = useState({ id: null, img: null })
  const imagenGaleria = useRef()

  const changeBackground = {
    backgroundColor: color,
  }
  const styleGalery = {
    display: displaygalery
  }

  const handleSelectImg = (id) => {
    let i = id-1
   setImgactive(data[i])
   setDisplaygalery('flex')
  }

  const handleCloseImg = () => {
    setDisplaygalery('none')
  }
  const handleChangeImgRight = () => {
    imagenGaleria.current.animate( [
      { transform: 'translateX(-2000px) scale(0.1)' },
      { transform: 'translate(0) scale(0.7)' },
    ], 500 );
    if(imgactive.id===data.length){
      let i = 0
      setImgactive(data[i])
    }else{
      let i = imgactive.id-1
      setImgactive(data[i+1])
    }
  }

  const handleChangeImgLeft = () => {
    imagenGaleria.current.animate( [
      { transform: 'translateX(2000px) scale(0.1)' },
      { transform: 'translate(0) scale(0.7)' },
    ], 500 );
    if(imgactive.id===1){
      let i = data.length -1
      setImgactive(data[i])
    }else{
      let i = imgactive.id-1
      setImgactive(data[i-1])
    }
  }

  return (
    <>
    <section className="colash" style={ changeBackground } >
      {data.map( img =>(
        <figure>
          <img key={img.id}
          onClick={ () => ( handleSelectImg(img.id) ) } 
          src={img.img} 
          alt=""/>
        </figure>
      ))
      }
    </section>
    <div className="Colash__selected-container" style={ styleGalery } >
      <div className="Colash__selected-imgs">
        <div>
          <img 
          onClick={ () => ( handleChangeImgLeft() ) }
          src={leftArrow} alt=""/>
        </div>
        <img
        ref={imagenGaleria}
        src={imgactive.img} alt=""/>
        <div>
          <img 
          onClick={ () => ( handleChangeImgRight() ) }
          src={rightArrow} alt=""/>
        </div>
        <div 
        onClick={ () => ( handleCloseImg() ) }
        className="Colash__selected-close">
          X
        </div>
      </div>
    </div>
    </>
  )
}

export default Colash;